import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
    name: 'myDate',
    pure: false,
})
export class MyDatePipe implements PipeTransform {
    public constructor(private _translocoService: TranslocoService) {
        this._translocoService.langChanges$.subscribe(
            (activeLang) => (this._lang = activeLang)
        );
    }

    private _lang: any = sessionStorage.getItem('language');

    public transform(
        value: string,
        outFormat = 'dd MMM yyyy',
        inputFormat = 'yyyy-MM-dd hh:mm a',
        ...args: unknown[]
    ): unknown {
        // const dt = DateTime.local().reconfigure({ locale: "ar", numberingSystem: "latn" });
        if (!value) return;

        let date: DateTime;

        if (inputFormat === 'yyyy-MM-dd hh:mm a') {
            date = DateTime.fromISO(value);
            if (!date.isValid) {
                date = DateTime.fromFormat(
                    value,
                    'yyyy-MM-dd hh:mm a'
                ).setLocale(this._lang);
            }
        } else {
            date = DateTime.fromFormat(value, inputFormat).setLocale(
                this._lang
            );
        }

        // date = DateTime.fromFormat(value, 'yyyy-MM-dd hh:mm a');
        if (!date.isValid) return 'not valid';

        // const date1 = date.reconfigure({locale: "ar"});
        return date.toFormat(outFormat);
    }
}
