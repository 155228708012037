import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'app/app.module';
import { environment } from 'environments/environment';

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));

console.log('ver', environment.appVersion);
console.log('env', environment.env);
console.log('env', environment.api_base_url);
