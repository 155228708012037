<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->


<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div
        class="relative flex flex-0 py-3 justify-center items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <img class="h-full min-w-auto w-auto" src="/assets/images/logo/pink-logo.png" alt="">
    </div>

    <!-- Content -->
    <div class="flex flex-col justify-center items-center flex-auto w-full">
        <div class="max-w-120 px-3"><img src="/assets/images/404-img.png" alt="Page not found"></div>
        <div class="py-4 px-3 font-black text-xl">
            {{ "error.head" | transloco }}
        </div>
        <p> {{ "error.p1" | transloco }}</p>
        <div class="mt-12  text-sm">
            {{ "error.p2" | transloco }}
            <div class="flex mt-3" dir="ltr">
                <div class="w-1/2 flex items-center gap-3 ">
                    <a href="tel: +966501976737"> +966 1976737</a>
                    <mat-icon svgIcon="heroicons_outline:phone" class="icon-size-4"></mat-icon>

                </div>
                <div class="w-1/2 flex items-center gap-3">
                    <a href="mailto:info@pinksapp.com">  info@pinksapp.com </a>
                    <mat-icon svgIcon="heroicons_outline:mail"  class="icon-size-4"></mat-icon>

                </div>
            </div>
        </div>
        <div class="h-6 "></div>
    </div>

    <!-- Footer -->
    <!--  <div
          class="relative flex flex-0 items-center justify-center w-full h-14 sm:h-20 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
          <span class="font-medium text-secondary">Pink &copy; asd</span>
      </div>-->

</div>

<!-- Quick chat -->
