import {Pipe, PipeTransform} from '@angular/core';
import { ProfileService } from '../services/profile.service';

@Pipe({
    name: 'getWorkingDays'
})
export class GetWorkingDaysPipe implements PipeTransform {
    weekDays: {[key: string]: string} = {
        'سبت': 'Saturday',
        'الأحد': 'Sunday',
        'الأثنين': 'Monday',
        'الثلاثاء': 'Tuesday',
        'الأربعاء': 'Wednesday',
        'الخميس': 'Thursday',
        'الجمعة': 'Friday',
    }
    constructor(private profileService: ProfileService) {}

    transform(value: string[]): string[] {
        return value.filter((day: string) => {
            return this.profileService.nonWorkingDays.indexOf(this.weekDays[day])
        })
    }

}
