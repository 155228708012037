import {Component} from '@angular/core';
import {MatSelectionListChange} from "@angular/material/list";
import {Category, CatService, Offer, Salon, SalonProfile} from "../../../api-models";
import {BookingService, ServiceItem} from "../../services/booking.service";
import {GroupServices, ProfileService} from "../../services/profile.service";
import {environment} from "../../../../environments/environment";
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'app-provider-services-list',
    templateUrl: './provider-services-list.component.html',
    styleUrls: ['./provider-services-list.component.scss']
})
export class ProviderServicesListComponent {


    salonProfile: SalonProfile;
    groupedServices: GroupServices;
    prefixImgUrl = environment.uploadsUrl + '/';
    selectedTab = '1';
    selectedServices: ServiceItem[];
    selectedOffer: any;
    enumTabs = {
        '0': '1',
        '1': '2',
    };
    lang:any="ar"
    constructor(
        private _profile: ProfileService,
        private _booking: BookingService,
        private _translocoService: TranslocoService

    ) {
    }


    ngOnInit(): void {
        this._profile.salonProfile$.subscribe(value => {

            this.salonProfile = value;

            this.selectedTab = this.salonProfile?.salon.specifications[0].specified_id.toString();
            this._booking.setSpecifications(this.salonProfile?.salon.specifications[0].specified_id.toString())
            this.groupedServices = this._profile.groupedServices;

        })
        this._translocoService.langChanges$.subscribe((activeLang) => {

            // Get the active lang
            this.lang = activeLang;

        });

        this._booking.selectedServices$.subscribe((fromBookingService) => {
            this.selectedServices = fromBookingService;
        })

    }


    selectionChange($event: MatSelectionListChange, cat: Category | Offer[]) {
        this._booking.addOrRemoveService($event.options[0].value as CatService, $event.options[0].selected);
    }

 /*   selectionOfferChange($event: MatSelectionListChange, offers: Offer[]) {
        debugger
        this._booking.addOrRemoveService($event.options[0].value as Offer, $event.options[0].selected);
    }*/

    checkIfSelected(serviceId: number): boolean {
        return !!this.selectedServices?.find(e => e.id === serviceId);
    }

/*    checkIfOfferSelected(offerId: number): boolean {

        return !!this.selectedOffer?.find(e => e.id === offerId);

    }*/


    async tapChanged(specified_id: any) {
        this._booking.resetSelected();

        this.selectedTab = specified_id.toString();
        this._booking.setSpecifications(this.selectedTab) ;

    }

    checkIfEmpty(catService: CatService[], specified_id: number) {
        return catService.filter((s)=> s.specified_id.toString() === specified_id.toString()).length;

    }
}
