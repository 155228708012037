import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'includes',
    pure: false,
})
export class IncludePipe implements PipeTransform {
    public transform(value: any, list: any[] = []): boolean {
        return list.length > 0 && [...list].includes(value);
    }
}
