<section class="my-4 p-3 min-h-40 relative sm:mx-4 sm:rounded">
    <div class="flex justify-between mb-1">
        <h1 class="text-primary text-xl font-bold">
            {{ "shared.our_services" | transloco }}
        </h1>

        <mat-chip-listbox
            *ngIf="!salonProfile?.salon.specifications.length"
            aria-label="Services selection"
        >
            <mat-chip-option
                *ngFor="let sp of salonProfile?.salon.specifications"
                [selected]="selectedTab == sp.specified_id"
            >
                <span *ngIf="lang == 'ar'">{{ sp.lang["ar"].name }}</span>
                <span *ngIf="lang != 'ar'">{{ sp.lang["en"].name }}</span>
            </mat-chip-option>
        </mat-chip-listbox>
        <div class="flex" *ngIf="salonProfile?.salon.specifications.length">
            <button
                *ngFor="let sp of salonProfile?.salon.specifications"
                class="services-btn"
                [class.active]="selectedTab == sp.specified_id"
                (click)="tapChanged(sp.specified_id)"
            >
                <span *ngIf="lang == 'ar'">{{ sp.lang["ar"].name }}</span>
                <span *ngIf="lang != 'ar'">{{ sp.lang["en"].name }}</span>
            </button>
            <!--  <button class="services-btn" [class.active]="selectedTab==1" (click)="selectedTab = 1"> الصالون</button>
              <button class="services-btn" [class.active]="selectedTab==2" (click)="selectedTab = 2"> خدمة منزلية
              </button>-->
        </div>
    </div>

    <mat-tab-group [selectedIndex]="selectedTab === '2' ? 1 : 0">
        <!--     (salon) or home services  -->
        <mat-tab>
            <mat-accordion>
                <ng-container *ngIf="salonProfile.offers?.length" label="First">
                    <mat-expansion-panel
                        *ngVar="salonProfile.offers as offers"
                        #hh2
                        MatAccordionTogglePosition
                    >
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div
                                    class="w-full flex justify-between items-center"
                                >
                                    <div class="flex items-center">
                                        <!--                                <mat-icon color="primary" class="ml-2" svgIcon="10k"></mat-icon>-->
                                        <div class="text-center pl-2">
                                            <div class="h-6 w-7">
                                                <mat-icon
                                                    color="primary"
                                                    svgIcon="heroicons_outline:chevron-left"
                                                ></mat-icon>
                                            </div>
                                            <!-- <img *ngIf="cat.icon" class="h-6 w-auto -scale-x-100"
                                                 [src]="prefixImgUrl + cat.icon" [alt]="cat.lang['ar'].name">-->
                                        </div>
                                        {{ "shared.offers" | transloco }}
                                    </div>
                                    <div
                                        *ngIf="
                                            !!serviceList1.selectedOptions
                                                .selected.length
                                        "
                                        class="w-2 h-2 bg-primary-600 rounded-full shadow-2xl"
                                    ></div>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <mat-selection-list
                            color="primary"
                            #serviceList1
                            (selectionChange)="selectionChange($event, offers)"
                        >
                            <ng-container *ngFor="let offer of offers">
                                <!--                                <pre >{{[offer.specified_id, offer.name, selectedTab]   | json   }}</pre>-->
                                <mat-expansion-panel
                                    class="offers-list"
                                    MatAccordionTogglePosition
                                    *ngIf="
                                        offer.specified_id?.toString() ===
                                        selectedTab.toString()
                                    "
                                >
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <!------ salon ------>
                                            <mat-list-option
                                                [value]="offer"
                                                *ngIf="
                                                    salonProfile.salon.canBook
                                                "
                                                [selected]="
                                                    checkIfSelected(offer.id)
                                                "
                                            >
                                                <div
                                                    class="flex justify-between items-center"
                                                    [class.hidden]="
                                                        !hh2.expanded
                                                    "
                                                >
                                                    <div
                                                        class="font-bold flex align-center"
                                                    >
                                                        {{
                                                            offer.name_lang[
                                                                "ar"
                                                            ]
                                                        }}
                                                    </div>
                                                    <div
                                                        class="flex offer-amount"
                                                    >
                                                        <div
                                                            class="flex column text-sm whitespace-nowrap"
                                                        >
                                                            <div
                                                                class="flex amountBefore"
                                                            >
                                                                <strong>
                                                                    {{
                                                                        offer.amountBefore
                                                                            | number
                                                                                : "1.2-2"
                                                                    }}
                                                                </strong>
                                                                <small>
                                                                    {{
                                                                        "shared.SR"
                                                                            | transloco
                                                                    }}</small
                                                                >
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="whitespace-nowrap"
                                                        >
                                                            <div class="flex">
                                                                <strong>
                                                                    {{
                                                                        offer.amount
                                                                            | number
                                                                                : "1.2-2"
                                                                    }}
                                                                </strong>
                                                                <small>
                                                                    {{
                                                                        "shared.SR"
                                                                            | transloco
                                                                    }}</small
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </mat-list-option>

                                            <div
                                                *ngIf="
                                                    !salonProfile.salon.canBook
                                                "
                                                class="service-item flex justify-between items-center"
                                                [class.hidden]="!hh2.expanded"
                                            >
                                                <div
                                                    class="font-bold flex align-center"
                                                >
                                                    <span
                                                        *ngIf="lang == 'ar'"
                                                        >{{
                                                            offer.name_lang[
                                                                "ar"
                                                            ]
                                                        }}</span
                                                    >
                                                    <span
                                                        *ngIf="lang != 'ar'"
                                                        >{{
                                                            offer.name_lang[
                                                                "en"
                                                            ]
                                                        }}</span
                                                    >
                                                </div>
                                                <div class="flex offer-amount">
                                                    <div
                                                        class="flex column text-sm whitespace-nowrap"
                                                    >
                                                        <div
                                                            class="flex amountBefore"
                                                        >
                                                            <strong>
                                                                {{
                                                                    offer.amountBefore
                                                                        | number
                                                                            : "1.2-2"
                                                                }}
                                                            </strong>
                                                            <small>
                                                                {{
                                                                    "shared.SR"
                                                                        | transloco
                                                                }}</small
                                                            >
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="whitespace-nowrap"
                                                    >
                                                        <div class="flex">
                                                            <strong>
                                                                {{
                                                                    offer.amount
                                                                        | number
                                                                            : "1.2-2"
                                                                }}
                                                            </strong>
                                                            <small>
                                                                {{
                                                                    "shared.SR"
                                                                        | transloco
                                                                }}</small
                                                            >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <div class="flex column offer-services">
                                        <div
                                            class="flex column service-descrption mb-3"
                                            *ngIf="offer.description_lang['ar']"
                                        >
                                            <span class="text-secondary" *ngIf="lang=='ar'">{{
                                                offer.description_lang["ar"]
                                            }}</span>
                                            <span class="text-secondary" *ngIf="lang!='ar'">{{
                                                offer.description_lang["en"]
                                            }}</span>
                                        </div>
                                        <h2 *ngIf="offer.services?.length">
                                            {{ "shared.services" | transloco }}
                                        </h2>
                                        <ul>
                                            <li
                                                *ngFor="
                                                    let service of offer.services;
                                                    let i = index
                                                "
                                            >
                                                <div class="flex column">
                                                    <span
                                                    *ngIf="lang=='ar'"
                                                        >-
                                                        {{
                                                            service.name_lang[
                                                                "ar"
                                                            ]
                                                        }}</span
                                                    >
                                                    <span
                                                    *ngIf="lang!='ar'"
                                                        >-
                                                        {{
                                                            service.name_lang[
                                                                "en"
                                                            ]
                                                        }}</span
                                                    >
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </mat-expansion-panel>
                            </ng-container>
                        </mat-selection-list>
                    </mat-expansion-panel>
                </ng-container>

                <ng-container
                    *ngFor="let homeCategory of groupedServices.salonServices"
                >
                    <mat-expansion-panel
                        *ngVar="homeCategory.categoryDetails as cat"
                        #hh2
                        MatAccordionTogglePosition
                    >
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div
                                    class="w-full flex justify-between items-center"
                                >
                                    <div class="flex items-center">
                                        <!--                                <mat-icon color="primary" class="ml-2" svgIcon="10k"></mat-icon>-->
                                        <div class="text-center pl-2">
                                            <div
                                                *ngIf="!cat.icon"
                                                class="h-6 w-7"
                                            >
                                                <mat-icon
                                                    color="primary"
                                                    svgIcon="heroicons_outline:chevron-left"
                                                ></mat-icon>
                                            </div>
                                            <img
                                                *ngIf="cat.icon"
                                                class="h-6 w-auto -scale-x-100"
                                                [src]="prefixImgUrl + cat.icon"
                                                [alt]="cat.lang['ar'].name"
                                            />
                                        </div>
                                        <span  *ngIf="lang=='ar'">
                                            {{ cat.lang["ar"].name }}
                                        </span>
                                        <span  *ngIf="lang!='ar'">
                                            {{ cat.lang["en"].name }}
                                        </span>

                                    </div>
                                    <div
                                        *ngIf="
                                            !!serviceList.selectedOptions
                                                .selected.length
                                        "
                                        class="w-2 h-2 bg-primary-600 rounded-full shadow-2xl"
                                    ></div>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <mat-selection-list
                            color="primary"
                            #serviceList
                            (selectionChange)="selectionChange($event, cat)"
                        >
                            <ng-container
                                *ngFor="let service of homeCategory.subServices"
                            >
                                <mat-list-option
                                    *ngIf="salonProfile?.salon.canBook"
                                    [value]="service"
                                    [selected]="
                                        checkIfSelected(service.service_id)
                                    "
                                >
                                    <div
                                        class="flex justify-between items-center"
                                        [class.hidden]="!hh2.expanded"
                                    >
                                        <div class="flex column">
                                            <div class="font-bold" >
                                                <span *ngIf="lang=='ar'">{{
                                                    service.service_lang["ar"]
                                                        .name
                                                }}</span>
                                                <span *ngIf="lang!='ar'">{{
                                                    service.service_lang["en"]
                                                        .name
                                                }}</span>

                                                <!-- - {{service.id}}-->
                                                <small
                                                    class="text-secondary px-2 whitespace-nowrap"
                                                >
                                                    {{
                                                        service.services_time
                                                            .time | timeToStr
                                                    }}</small
                                                >
                                            </div>
                                            <small class="text-secondary" *ngIf="lang=='ar'">{{
                                                service.description_lang["ar"]
                                            }}</small>
                                            <small class="text-secondary" *ngIf="lang!='ar'">{{
                                                service.description_lang["en"]
                                            }}</small>
                                        </div>
                                        <div class="text-sm whitespace-nowrap">
                                            <strong>
                                                {{ service.amount }}
                                            </strong>
                                            <small>
                                                {{
                                                    "shared.SR" | transloco
                                                }}</small
                                            >
                                        </div>
                                    </div>
                                </mat-list-option>
                                <div
                                    *ngIf="!salonProfile?.salon.canBook"
                                    class="service-item flex justify-between items-center"
                                    [class.hidden]="!hh2.expanded"
                                >
                                    <div class="flex column">
                                        <div class="font-bold">
                                            <span *ngIf="lang=='ar'">{{
                                                service.service_lang["ar"].name
                                            }}</span>
                                            <span *ngIf="lang!='ar'">{{
                                                service.service_lang["en"].name
                                            }}</span>

                                            <!-- - {{service.id}}-->
                                            <small
                                                class="text-secondary px-2 whitespace-nowrap"
                                            >
                                                {{
                                                    service.services_time.time
                                                        | timeToStr
                                                }}</small
                                            >
                                        </div>
                                        <small class="text-secondary" *ngIf="lang=='ar'">{{
                                            service.description_lang["ar"]
                                        }}</small>
                                        <small class="text-secondary" *ngIf="lang!='ar'">{{
                                            service.description_lang["en"]
                                        }}</small>
                                    </div>
                                    <div class="text-sm whitespace-nowrap">
                                        <strong>
                                            {{
                                                service.amount
                                                    | number : "1.2-2"
                                            }}
                                        </strong>
                                        <small>
                                            {{ "shared.SR" | transloco }}</small
                                        >
                                    </div>
                                </div>
                            </ng-container>
                        </mat-selection-list>
                    </mat-expansion-panel>
                </ng-container>
            </mat-accordion>
        </mat-tab>

        <!--     salon or (home services)  -->
        <mat-tab label="second">
            <mat-accordion>
                <!-- offers -->
                <ng-container *ngIf="salonProfile.offers?.length" label="First">
                    <mat-expansion-panel
                        *ngVar="salonProfile.offers as offers"
                        #hh2
                        MatAccordionTogglePosition
                    >
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div
                                    class="w-full flex justify-between items-center"
                                >
                                    <div class="flex items-center">
                                        <!--                                <mat-icon color="primary" class="ml-2" svgIcon="10k"></mat-icon>-->
                                        <div class="text-center pl-2">
                                            <div class="h-6 w-7">
                                                <mat-icon
                                                    color="primary"
                                                    svgIcon="heroicons_outline:chevron-left"
                                                ></mat-icon>
                                            </div>
                                            <!-- <img *ngIf="cat.icon" class="h-6 w-auto -scale-x-100"
                                                  [src]="prefixImgUrl + cat.icon" [alt]="cat.lang['ar'].name">-->
                                        </div>
                                        {{ "shared.offers" | transloco }}
                                    </div>
                                    <div
                                        *ngIf="
                                            !!serviceList1.selectedOptions
                                                .selected.length
                                        "
                                        class="w-2 h-2 bg-primary-600 rounded-full shadow-2xl"
                                    ></div>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <mat-selection-list
                            color="primary"
                            #serviceList1
                            (selectionChange)="selectionChange($event, offers)"
                        >
                            <ng-container *ngFor="let offer of offers">
                                <mat-expansion-panel
                                    class="offers-list"
                                    MatAccordionTogglePosition
                                    *ngIf="
                                        offer.specified_id?.toString() ===
                                        selectedTab.toString()
                                    "
                                >
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <!------ salon ------>
                                            <mat-list-option
                                                [value]="offer"
                                                *ngIf="
                                                    salonProfile.salon.canBook
                                                "
                                                [selected]="
                                                    checkIfSelected(offer.id)
                                                "
                                            >
                                                <div
                                                    class="flex justify-between items-center"
                                                    [class.hidden]="
                                                        !hh2.expanded
                                                    "
                                                >
                                                    <div
                                                        class="font-bold flex align-center"
                                                        *ngIf="lang=='ar'"
                                                    >
                                                        {{
                                                            offer.name_lang[
                                                                "ar"
                                                            ]
                                                        }}
                                                    </div>
                                                    <div
                                                        class="font-bold flex align-center"
                                                        *ngIf="lang!='ar'"
                                                    >
                                                        {{
                                                            offer.name_lang[
                                                                "en"
                                                            ]
                                                        }}
                                                    </div>
                                                    <div
                                                        class="flex offer-amount"
                                                    >
                                                        <div
                                                            class="flex column text-sm whitespace-nowrap"
                                                        >
                                                            <div
                                                                class="flex amountBefore"
                                                            >
                                                                <strong>
                                                                    {{
                                                                        offer.amountBefore
                                                                            | number
                                                                                : "1.2-2"
                                                                    }}
                                                                </strong>
                                                                <small>
                                                                    {{
                                                                        "shared.SR"
                                                                            | transloco
                                                                    }}</small
                                                                >
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="whitespace-nowrap"
                                                        >
                                                            <div class="flex">
                                                                <strong>
                                                                    {{
                                                                        offer.amount
                                                                            | number
                                                                                : "1.2-2"
                                                                    }}
                                                                </strong>
                                                                <small>
                                                                    {{
                                                                        "shared.SR"
                                                                            | transloco
                                                                    }}</small
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </mat-list-option>

                                            <div
                                                *ngIf="
                                                    !salonProfile.salon.canBook
                                                "
                                                class="service-item flex justify-between items-center"
                                                [class.hidden]="!hh2.expanded"
                                            >
                                                <div
                                                    class="font-bold flex align-center"
                                                    *ngIf="lang=='ar'"
                                                >
                                                    {{ offer.name_lang["ar"] }}
                                                </div>
                                                <div
                                                    class="font-bold flex align-center"
                                                    *ngIf="lang!='ar'"
                                                >
                                                    {{ offer.name_lang["en"] }}
                                                </div>
                                                <div class="flex offer-amount">
                                                    <div
                                                        class="flex column text-sm whitespace-nowrap"
                                                    >
                                                        <div
                                                            class="flex amountBefore"
                                                        >
                                                            <strong>
                                                                {{
                                                                    offer.amountBefore
                                                                        | number
                                                                            : "1.2-2"
                                                                }}
                                                            </strong>
                                                            <small>
                                                                {{
                                                                    "shared.SR"
                                                                        | transloco
                                                                }}</small
                                                            >
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="whitespace-nowrap"
                                                    >
                                                        <div class="flex">
                                                            <strong>
                                                                {{
                                                                    offer.amount
                                                                        | number
                                                                            : "1.2-2"
                                                                }}
                                                            </strong>
                                                            <small>
                                                                {{
                                                                    "shared.SR"
                                                                        | transloco
                                                                }}</small
                                                            >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <div class="flex column offer-services">
                                        <div
                                            class="flex column service-descrption mb-3"
                                            *ngIf="offer.description_lang['ar']"
                                        >
                                            <span *ngIf="lang=='ar'">{{
                                                offer.description_lang["ar"]
                                            }}</span>
                                            <span *ngIf="lang!='ar'">{{
                                                offer.description_lang["en"]
                                            }}</span>
                                        </div>
                                        <h2 *ngIf="offer.services?.length">
                                            {{ "shared.services" | transloco }}
                                        </h2>
                                        <ul>
                                            <li
                                                *ngFor="
                                                    let service of offer.services
                                                "
                                            >
                                                <div class="flex column">
                                                    <span
                                                    *ngIf="lang=='ar'"
                                                        >-
                                                        {{
                                                            service.name_lang[
                                                                "ar"
                                                            ]
                                                        }}</span
                                                    >
                                                    <span
                                                    *ngIf="lang!='ar'"
                                                        >-
                                                        {{
                                                            service.name_lang[
                                                                "en"
                                                            ]
                                                        }}</span
                                                    >
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </mat-expansion-panel>
                            </ng-container>
                        </mat-selection-list>
                    </mat-expansion-panel>
                </ng-container>
                <!-- services -->
                <ng-container
                    *ngFor="let homeCategory of groupedServices.homeServices"
                >
                    <mat-expansion-panel
                        *ngVar="homeCategory.categoryDetails as cat"
                        #hh
                        MatAccordionTogglePosition
                    >
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div
                                    class="w-full flex justify-between items-center"
                                >
                                    <div class="flex items-center">
                                        <!--                                <mat-icon color="primary" class="ml-2" svgIcon="10k"></mat-icon>-->
                                        <div class="text-center pl-2">
                                            <div
                                                *ngIf="!cat.icon"
                                                class="h-6 w-7"
                                            >
                                                <mat-icon
                                                    color="primary"
                                                    svgIcon="heroicons_outline:chevron-left"
                                                ></mat-icon>
                                            </div>
                                            <img
                                                *ngIf="cat.icon"
                                                class="h-6 w-auto -scale-x-100"
                                                [src]="prefixImgUrl + cat.icon"
                                                [alt]="cat.lang['ar'].name"
                                            />
                                        </div>
                                        <span *ngIf="lang=='ar'">{{ cat.lang["ar"].name }}</span>
                                        <span *ngIf="lang!='ar'">{{ cat.lang["en"].name }}</span>

                                    </div>
                                    <div
                                        *ngIf="
                                            !!serviceList.selectedOptions
                                                .selected.length
                                        "
                                        class="w-2 h-2 bg-primary-600 rounded-full shadow-2xl"
                                    ></div>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <mat-selection-list
                            color="primary"
                            #serviceList
                            [class.hidden]="!hh.expanded"
                            (selectionChange)="selectionChange($event, cat)"
                        >
                            <ng-container
                                *ngFor="let service of homeCategory.subServices"
                            >
                                <!------ home Service ------>
                                <mat-list-option
                                    *ngIf="salonProfile.salon.canBook"
                                    [value]="service"
                                    [selected]="
                                        checkIfSelected(service.service_id)
                                    "
                                >
                                    <div
                                        class="flex justify-between items-center"
                                    >
                                        <div class="flex column">
                                            <div class="font-bold">
                                                <span *ngIf="lang=='ar'">{{
                                                    service.service_lang["ar"]
                                                        .name
                                                }}</span>
                                                <span *ngIf="lang=='en'">{{
                                                    service.service_lang["en"]
                                                        .name
                                                }}</span>

                                                <!-- - {{service.id}}-->
                                                <small
                                                    class="text-secondary px-2 whitespace-nowrap"
                                                >
                                                    {{
                                                        service.services_time
                                                            .time | timeToStr
                                                    }}</small
                                                >
                                            </div>
                                            <small class="text-secondary" *ngIf="lang=='ar'">{{
                                                service.description_lang["ar"]
                                            }}</small>
                                            <small class="text-secondary" *ngIf="lang=='en'">{{
                                                service.description_lang["en"]
                                            }}</small>
                                        </div>
                                        <div class="text-sm whitespace-nowrap">
                                            <strong>
                                                {{
                                                    service.amount
                                                        | number : "1.2-2"
                                                }}
                                            </strong>
                                            <small>
                                                {{
                                                    "shared.SR" | transloco
                                                }}</small
                                            >
                                        </div>
                                    </div>
                                </mat-list-option>
                                <div
                                    *ngIf="!salonProfile.salon.canBook"
                                    class="service-item flex justify-between items-center"
                                >
                                    <div class="flex column">
                                        <div class="font-bold">
                                            <span *ngIf="lang=='ar'">{{
                                                service.service_lang["ar"].name
                                            }}</span>
                                            <span *ngIf="lang=='en'">{{
                                                service.service_lang["en"].name
                                            }}</span>

                                            <!-- - {{service.id}}-->
                                            <small
                                                class="text-secondary px-2 whitespace-nowrap"
                                            >
                                                {{
                                                    service.services_time.time
                                                        | timeToStr
                                                }}</small
                                            >
                                        </div>
                                        <small class="text-secondary"  *ngIf="lang=='ar'">{{
                                            service.description_lang["ar"]
                                        }}</small>
                                        <small class="text-secondary"  *ngIf="lang=='en'">{{
                                            service.description_lang["en"]
                                        }}</small>
                                    </div>
                                    <div class="text-sm whitespace-nowrap">
                                        <strong>
                                            {{
                                                service.amount
                                                    | number : "1.2-2"
                                            }}
                                        </strong>
                                        <small>
                                            {{ "shared.SR" | transloco }}</small
                                        >
                                    </div>
                                </div>
                            </ng-container>
                        </mat-selection-list>
                    </mat-expansion-panel>
                </ng-container>
            </mat-accordion>
        </mat-tab>
    </mat-tab-group>
</section>
