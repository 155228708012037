import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'timeToStr',
  pure:false
})
export class TimeToStrPipe implements PipeTransform {
    lang:any=sessionStorage.getItem('language')
    constructor(private _translocoService:TranslocoService){
        this._translocoService.langChanges$.subscribe((activeLang) => {
            // Get the active lang
            this.lang = activeLang;
        });
    }
  transform(value: string | number, ...args: unknown[]): unknown {
      if(typeof value == 'string' ){
          value = Number(value);
      }
      const hours = Math.floor(value / 60);
      const minutes = value % 60;
      if(this.lang=='ar')
      {return '(' + (hours ? hours + ":" : '') + minutes + " " + (!hours? 'دقيقة' : 'ساعة') + ')';}
      else{
        return '(' + (hours ? hours + ":" : '') + minutes + " " + (!hours? 'min' : 'h') + ')';
      }
  }

}
