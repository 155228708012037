import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { CatService, Offer } from '../../api-models';
import { DateTime } from 'luxon';
import { ProfileService } from './profile.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CancelReasonApi } from '../../cancel-reason-api.model';
import { TimeSlotResponse } from '../utils';

export interface ServiceItem {
    minutes: number;
    amount: number;
    name: string;
    id: number;
    obj: any;
    isOffer: boolean;
}

export interface BookingMeta {
    location: { lat: number; lng: number };
    locationAddress: string;
    sumPrice: number;
    sumPeriods: number;
    servicesCount: number;
    dateTime?: string;
    specification?: { id: number; name: string };
    discount?: number;
    promoCode?: string;
}

export interface StepModel {
    id: number;
    title: string;
    titleEn: string;
    icon: string;
    navTo: string;
    canOpen: boolean;
    valid: boolean;
}

export interface VerifyApi {
    success: boolean;
    data: {
        name: string;
        mobile: string;
    };
}

export interface VerifyOtpApi {
    success: boolean;
    data: {
        id: number;
        name: string;
        mobile: string;
        access_token: string;
    };
}

export interface BookingInfo {
    status_dic: any[];
    status: number;
    address: string;
    code: string;
    date: string;
    id: string;
    latitude: string;
    longitude: string;
    name: string;
    client: { id: number; name: string; mobile: string };
    phone: string;
    services: any[];
    offers: any[];
    summary: { total: string; vat: string; fees: number };
    workingDays: any[];
    booking: any;
    specified: string;
    userAddress: {
        user_id: number;
        address: string;
        location: string;
        lat: number;
        long: number;
        type: number;
        default: number;
        updated_at: string;
        created_at: string;
        id: number;
    };
    offersDiscount: number;
}

interface CancelReasonBody {
    booking_id: string;
    booking_reason_id: number;
    comment: string;
}

@Injectable({
    providedIn: 'root',
})
export class BookingService {
    selectedServices: ServiceItem[] = [];
    private selectedServicesSubject = new BehaviorSubject<ServiceItem[]>(null);
    readonly selectedServices$ = this.selectedServicesSubject.asObservable();

    bookingMeta: BookingMeta = {} as BookingMeta;
    public bookingMetaSubject = new BehaviorSubject<BookingMeta>(null);
    readonly bookingMeta$ = this.bookingMetaSubject.asObservable();

    bookingInfo: BookingInfo;
    private _selectedSpecified_id: string = '1';
    set selectedSpecified_id(v: string) {
        this._selectedSpecified_id = v;
        this.steps =
            this._selectedSpecified_id.toString() === '1'
                ? this.steps1
                : this.steps2;
    }

    get selectedSpecified_id(): string {
        return this._selectedSpecified_id;
    }

    steps1: StepModel[] = [
        {
            id: 1,
            title: 'اختيار الخدمات',
            titleEn: 'Selecting Services',
            icon: 'checklist',
            navTo: '/book/services',
            canOpen: true,
            valid: false,
        },
        {
            id: 2,
            title: 'تحديد الوقت',
            titleEn: 'Choosing the Time',
            icon: 'access_time',
            navTo: '/book/time',
            canOpen: false,
            valid: false,
        },
        {
            id: 3,
            title: 'التأكيد والدفع',
            titleEn: 'Confirmation and Payment',
            icon: 'payments',
            navTo: '/book/summary',
            canOpen: false,
            valid: false,
        },
    ];
    steps2: StepModel[] = [
        {
            id: 1,
            title: 'اختيار الخدمات',
            titleEn: 'Selecting Services',
            icon: 'checklist',
            navTo: '/book/services',
            canOpen: true,
            valid: false,
        },
        {
            id: 2,
            title: 'تحديد الوقت',
            titleEn: 'Choosing the Time',
            icon: 'access_time',
            navTo: '/book/time',
            canOpen: false,
            valid: false,
        },
        {
            id: 3,
            title: 'تحديد المكان',
            titleEn: 'Selecting the Location',
            icon: 'heroicons_solid:location-marker',
            navTo: '/book/location',
            canOpen: false,
            valid: false,
        },
        {
            id: 4,
            title: 'التأكيد والدفع',
            titleEn: 'Confirmation and Payment',
            icon: 'payments',
            navTo: '/book/summary',
            canOpen: false,
            valid: false,
        },
    ];
    steps: StepModel[] = this.steps1;

    set accessToken(accessToken) {
        localStorage.setItem('access_token', accessToken);
        localStorage.setItem('access_token_date', DateTime.now().toISO());
    }

    get accessToken() {
        const lastLoginDate = DateTime.fromISO(
            localStorage.getItem('access_token_date')
        );
        const tokenDateDiffDays = DateTime.now().diff(lastLoginDate);
        const tokenDateDiffDaysByHours =
            tokenDateDiffDays.milliseconds / 1000 / 60 / 60;
        if (tokenDateDiffDaysByHours >= 1) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('user');
        }
        return localStorage.getItem('access_token');
    }

    set user(user) {
        localStorage.setItem('user', JSON.stringify(user));
    }

    get user(): {
        id: number;
        name: string;
        mobile: string;
        access_token: string;
    } {
        const tokenDateDiffDays = DateTime.fromISO(
            localStorage.getItem('access_token_date')
        ).diff(DateTime.now()).days;
        if (tokenDateDiffDays >= 2) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('user');
        }
        return JSON.parse(localStorage.getItem('user'));
    }

    constructor(
        private _httpClient: HttpClient,
        private _profile: ProfileService
    ) {
        this.selectedServices$.subscribe((services) => {
            let sumPeriods = 0;
            let sumPrice = 0;
            services?.forEach((e) => {
                sumPeriods += Number(e.minutes);
                sumPrice += Number(e.amount);
            });

            this.bookingMeta.sumPeriods = sumPeriods;
            this.bookingMeta.sumPrice = sumPrice;
            this.bookingMeta.servicesCount = services?.length || 0;
            this.bookingMeta.dateTime = null;
            this.bookingMetaSubject.next(this.bookingMeta);
        });

        this.bookingMeta$.subscribe((meta) => {
            this.steps.find((i) => i.id === 1).valid = !!meta.servicesCount;
            this.steps.find((i) => i.id === 2).valid = !!meta.dateTime;

            this.steps.find((i) => i.id === 2).canOpen = !!meta.servicesCount;

            if (this.selectedSpecified_id.toString() === '1') {
                this.steps.find((i) => i.id === 3).canOpen =
                    !!meta.dateTime && !!meta.servicesCount;
            } else {
                // TODO: to skip location make all true
                this.steps.find((i) => i.id === 3).valid = !!meta.location;
                this.steps.find((i) => i.id === 3).canOpen = !!meta.dateTime;
                this.steps.find((i) => i.id === 4).canOpen =
                    !!meta.dateTime &&
                    !!meta.servicesCount &&
                    !!meta.locationAddress;
            }
        });
    }

    addOrRemoveService(service: CatService | Offer, addOrRemove: boolean) {
        let _service: ServiceItem;
        if ('amountBefore' in service) {
            _service = {
                minutes: null,
                amount: Number(service.amount.replace(',', '')),
                name: service.name_lang['ar'],
                id: service.id,
                obj: service,
                isOffer: true,
            };
        } else {
            _service = {
                minutes: service.services_time.time,
                amount: Number(service.amount),
                name: service.service_lang['ar'].name,
                id: service.service_id,
                obj: service,
                isOffer: false,
            };
        }
        if (addOrRemove) {
            if (!this.selectedServices.find((a) => a.id === _service.id)) {
                this.selectedServices.push(_service);
            }
        } else {
            this.selectedServices = this.selectedServices.filter(
                (a) => a.id !== _service.id
            );
        }

        this.selectedServicesSubject.next(this.selectedServices);
    }
    /*    addOrRemoveOffer(offer: Offer, addOrRemove: boolean) {
            const _service: ServiceItem = {
                minutes: null,
                amount: Number(offer.amount),
                name: offer.name_lang['ar'],
                id: offer.id,
                obj: offer,
                isOffer: true
            }
            if (addOrRemove) {
                if (!this.selectedServices.find(a => a.id === _service.id)) {
                    this.selectedServices.push(_service);
                }
            } else {
                this.selectedServices = this.selectedServices.filter(a => a.id !== _service.id)
            }

            this.selectedServicesSubject.next(this.selectedServices);
        }*/

    resetSelected() {
        this.selectedServices = [];
        this.selectedServicesSubject.next(this.selectedServices);
    }

    setSelectedTime(date: string) {
        this.bookingMeta.dateTime = date;
        this.bookingMetaSubject.next(this.bookingMeta);
    }

    setLocation(markerPosition: { lng: number; lat: number }, address: string) {
        this.bookingMeta.location = markerPosition;
        this.bookingMeta.locationAddress = address;
        this.bookingMetaSubject.next(this.bookingMeta);
    }

    setSpecifications(id: string) {
        const selectedIndex = this._profile?.salon.specifications.find(
            (item) => item.specified_id == +id
        );
        const sp = {
            id: selectedIndex.specified_id || null,
            name: selectedIndex.lang['ar'].name,
        };
        this.bookingMeta.specification = sp;
        this.selectedSpecified_id = id.toString();
        this.bookingMetaSubject.next(this.bookingMeta);
    }

    async newUserData(name: string, email: string = null) {
        const response = await lastValueFrom(
            this._httpClient.post<VerifyOtpApi>(
                environment.api_base_url + '/edit-client/' + this.user.id,
                {
                    name,
                    email,
                }
            )
        );

        if (response.success) {
            this.accessToken = response.data.access_token;
            this.user = response.data;
        }

        return response.success;
    }

    async userVerify(mobileNo: string) {
        const response = await lastValueFrom(
            this._httpClient.post<VerifyApi>(
                environment.api_base_url + '/verify',
                {
                    mobile: mobileNo,
                    salon_id: this._profile.salon.id,
                }
            )
        );
        return response.success;
    }

    async userVerifyOtp(mobileNo: string, otp: string) {
        const response = await lastValueFrom(
            this._httpClient.post<VerifyOtpApi>(
                environment.api_base_url + '/verifyOtp',
                {
                    mobile: mobileNo,
                    salon_id: this._profile.salon.id,
                    otp: otp,
                }
            )
        );
        if (response.success) {
            this.accessToken = response.data.access_token;
            this.user = response.data;
        }
        return response.success;
    }

    async submitBooking() {
        if (this.accessToken) {
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.accessToken,
            });
            let options = { headers: headers };
            let body: any = {
                user_id: this.user.id,
                slot: DateTime.fromFormat(
                    this.bookingMeta.dateTime,
                    'yyyy-MM-dd hh:mm a'
                ).toFormat('HH:mm'),
                salon_id: this._profile.salon.id,
                specified_id: this.bookingMeta.specification.id,
                services: this.selectedServices
                    .filter((s) => !s.isOffer)
                    .map((s) => s.id)
                    .join(','),
                offers: this.selectedServices
                    .filter((o) => o.isOffer)
                    .map((o) => ({
                        id: o.id,
                        services: o.obj.services.map((s) => ({
                            service_id: s.id,
                            staff_id: null,
                        })),
                    })),
                date: DateTime.fromFormat(
                    this.bookingMeta.dateTime,
                    'yyyy-MM-dd hh:mm a'
                ).toFormat('yyyy-MM-dd'),
                promocode: this.bookingMetaSubject.getValue()?.promoCode,
            };
            if (this.bookingMeta.specification.id.toString() === '2') {
                body = {
                    ...body,
                    long: this.bookingMeta.location.lng,
                    lat: this.bookingMeta.location.lat,
                    location: this.bookingMeta.locationAddress,
                };
            }
            return await lastValueFrom(
                this._httpClient.post(
                    environment.api_base_url + '/booking',
                    body,
                    options
                )
            );
        }
    }

    async doneBooking(
        eid: any
    ): Promise<{ selectedServices: any[]; bookingInfo: any }> {
        const response: any = await lastValueFrom(
            this._httpClient.get(
                environment.api_base_url + '/thankyou/' + eid,
                {
                    headers: {
                        lang: 'ar',
                    },
                }
            )
        ).catch((error) => {
            console.log(error.error);
        });
        this.bookingInfo = response;

        //reset
        this.selectedServices = [];
        this.bookingMeta = {} as BookingMeta;
        this.selectedServicesSubject.next(this.selectedServices);
        this.bookingMetaSubject.next(this.bookingMeta);

        return {
            bookingInfo: this.bookingInfo,
            selectedServices: this.bookingInfo.services,
        };
    }

    async cancelBooking(body: CancelReasonBody) {
        return await lastValueFrom(
            this._httpClient.post<any>(
                environment.api_base_url + '/cancel',
                body
            )
        );
    }

    async cancelReason(eid): Promise<CancelReasonApi> {
        return await lastValueFrom(
            this._httpClient.get<CancelReasonApi>(
                environment.api_base_url + '/cancelReason/' + eid
            )
        );
    }
    async promoCode(eid, promo, total?: number): Promise<CancelReasonApi> {
        return await lastValueFrom(
            this._httpClient.get<CancelReasonApi>(
                environment.api_base_url +
                    '/voucher/check?promocode=' +
                    promo +
                    '&salon_id=' +
                    eid +
                    '&total=' +
                    total
            )
        );
    }

    public getTimeSlot = (date: string): Observable<TimeSlotResponse> => {
        const url = new URL(
            environment.api_base_url +
                '/time/slots?start_date=' +
                date +
                '&salon_id=' +
                this._profile.salon?.id
        );

        return this._httpClient.get<TimeSlotResponse>(url.toString());
    };
}
