import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'convertFloatNumber' })
export class ConvertFloatPipe implements PipeTransform {
    public transform(value: any): number {
        return !!value && value.includes(',')
            ? +value.replaceAll(',', '.')
            : +value;
    }
}
