import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class UpdateMetaTagsService {
    public constructor(
        private readonly _title: Title,
        private readonly _meta: Meta
    ) {}

    public updateMetaTags = (definitions: MetaDefinitions): void => {
        const { title, description, url, imageUrl, keywords } = definitions;

        const metaTags: MetaDefinition[] = [
            { property: 'og:title', content: title },
            { property: 'og:description', content: description },
            { property: 'og:image', content: imageUrl },
            // { property: 'og:url', content: url },
            { name: 'keywords', content: keywords },
            { name: 'description', content: description },
            { name: 'author', content: title },
            { name: 'twitter:card', content: 'summary_large_image' },
            // { name: 'twitter:url', content: url },
            { name: 'twitter:title', content: title },
            { name: 'twitter:description', content: description },
            { name: 'twitter:image', content: imageUrl },
        ];

        this._title.setTitle(title);
        this.updateMeta(metaTags);
    };

    private updateMeta = (metaTags: MetaDefinition[]): void => {
        metaTags.forEach((tag) => {
            if (tag.content) {
                this._meta.updateTag(
                    tag,
                    !!tag.property
                        ? `property = '${tag.property}'`
                        : `name = '${tag.name}'`
                );
            }
        });
    };
}

export interface MetaDefinitions {
    title: string;
    description: string;
    url?: string;
    imageUrl?: string;
    keywords?: string;
}
