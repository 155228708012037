import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProfileService } from './shared/services/profile.service';
import { TranslocoService } from '@ngneat/transloco';
import { UpdateMetaTagsService } from './shared/services/update-meta-tags.service';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { SalonProfile } from './api-models';
import { environment } from 'environments/environment';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    public constructor(
        private readonly _profile: ProfileService,
        private readonly _translocoService: TranslocoService,
        private readonly _updateMetaTagsService: UpdateMetaTagsService
    ) {}

    private readonly _destroyAll$ = new ReplaySubject<unknown>(1);

    private readonly _uploadsUrl: string = environment.uploadsUrl;

    private _lang: string = sessionStorage.getItem('language') as string;

    public ngOnInit(): void {
        this._translocoService.langChanges$.subscribe((activeLang) => {
            this._lang = activeLang;
            const html = document.getElementById('htmlTag');
            html.dir = this._lang == 'ar' ? 'rtl' : 'ltr';
        });

        this._profile.salonProfile$
            .pipe(
                filter(Boolean),
                tap(({ salon }: SalonProfile) => {
                    const { lang, logo } = salon;

                    this._updateMetaTagsService.updateMetaTags({
                        title: lang[this._lang]?.name,
                        description: lang[this._lang]?.description,
                        imageUrl: `${this._uploadsUrl}/${logo}`,
                    });
                }),
                takeUntil(this._destroyAll$)
            )
            .subscribe();
    }

    public ngOnDestroy(): void {
        this._destroyAll$.next(undefined);
        this._destroyAll$.complete();
    }
}
