<ng-container *ngIf="view === 0 && salonProfile?.salon?.canBook">
    <div #callActionRef [style.height.px]="callAction?.offsetHeight + 12"></div>
    <div
        *ngIf="showCartSum"
        @fadeIn
        @fadeOut
        class="fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-50 z-10"
    ></div>
    <div
        class="fixed bottom-0 bg-white z-50 mat-elevation-z12 overflow-hidden"
        [style.width.px]="callActionRef.offsetWidth"
    >
        <div
            #callAction
            class="flex justify-between items-center py-3 px-2"
            [class.mat-elevation-z6]="showShadow"
        >
            <div class="flex-grow flex items-center">
                <button mat-icon-button (click)="showCartSum = !showCartSum">
                    <mat-icon
                        svgIcon="heroicons_outline:chevron-down"
                    ></mat-icon>
                </button>
                <div class="px-2">
                    <div>
                        <strong class="text-primary">{{
                            selectedServices?.length
                        }}</strong>
                        <span>
                            {{ "shared.selected_services" | transloco }}</span
                        >
                    </div>
                    <div class="text-secondary">
                        <span> {{ "shared.cost" | transloco }}</span
                        ><strong>
                            {{ bookingMeta?.sumPrice | number : "1.2-2" }}
                        </strong>
                        <small>{{ "shared.SR" | transloco }}</small>
                    </div>
                </div>
            </div>
            <a
                [routerLink]="[actionBtnGoTo]"
                mat-raised-button
                color="primary"
                class="mx-3"
            >
                {{ actionBtnText }}
            </a>
        </div>

        <div
            #scrollablePnl
            class="px-4 max-h-64 overflow-y-auto"
            @expandCollapse
            *ngIf="showCartSum"
            (scroll)="anyAction($event)"
        >
            <mat-list>
                <ng-container *ngIf="!selectedServices?.length">
                    <div
                        class="h-18 flex justify-center items-center text-secondary text-sm"
                    >
                        {{ "shared.no_service_selected" | transloco }}
                    </div>
                </ng-container>
                <ng-container *ngFor="let service of selectedServices">
                    <mat-list-item @expandCollapse>
                        <button
                            mat-icon-button
                            matListItemIcon
                            class="mx-1"
                            (click)="removeItem(service)"
                        >
                            <mat-icon
                                color="primary"
                                svgIcon="heroicons_outline:x-circle"
                            ></mat-icon>
                        </button>
                        <div class="flex flex-col">
                            <div class="flex justify-between">
                                <div class="flex flex-col">
                                    <div class="font-bold" *ngIf="lang == 'ar'">
                                        {{ service.name }}
                                    </div>
                                    <div
                                        class="font-bold"
                                        *ngIf="
                                            lang == 'en' &&
                                            service.obj.service_lang
                                        "
                                    >
                                        {{ service.obj.service_lang.en.name }}
                                    </div>
                                    <div
                                        class="font-bold"
                                        *ngIf="
                                            lang == 'en' &&
                                            !service.obj.service_lang
                                        "
                                    >
                                        {{ service.obj.name_lang.en }}
                                    </div>
                                </div>
                                <div class="text-sm">
                                    <strong>
                                        {{
                                            +service?.obj?.amountBefore ||
                                                service?.amount
                                                | number : "1.2-2"
                                        }}
                                    </strong>
                                    <small>
                                        {{ "shared.SR" | transloco }}</small
                                    >
                                </div>
                            </div>
                            <div
                                *ngIf="service.isOffer"
                                class="flex justify-between"
                            >
                                <div class="flex flex-col">
                                    <div class="text-md text-secondary">
                                        {{
                                            "shared.offers_discount" | transloco
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="text-sm text-secondary text-red-400"
                                >
                                    <strong
                                        >-
                                        {{
                                            +service?.obj?.amountBefore -
                                                +service?.obj?.amount
                                                | number : "1.2-2"
                                        }}
                                    </strong>
                                    <small>
                                        {{ "shared.SR" | transloco }}</small
                                    >
                                </div>
                            </div>
                        </div>
                    </mat-list-item>
                </ng-container>
            </mat-list>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="view === 1 && salonProfile?.salon?.canBook">
    <div
        #callActionRef1
        [style.height.px]="callAction?.offsetHeight + 12"
    ></div>
    <div
        class="fixed bottom-0 bg-white z-50 mat-elevation-z12 overflow-hidden"
        [style.width.px]="callActionRef1.offsetWidth"
    >
        <div #callAction class="flex justify-center items-center py-3 px-2">
            <a
                *ngIf="actionBtnGoTo"
                [routerLink]="[actionBtnGoTo]"
                mat-raised-button
                color="primary"
                class="mx-3"
            >
                <span class="px-4">{{
                    "shared.book_with_us" | transloco
                }}</span>
            </a>

            <button
                *ngIf="!actionBtnGoTo"
                mat-raised-button
                color="primary"
                class="mx-3"
            >
                <span class="px-4">{{
                    "shared.book_with_us" | transloco
                }}</span>
            </button>
        </div>
    </div>
</ng-container>
