import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, tap} from "rxjs";
import {Router} from "@angular/router";
import {Category, CatService, Offer, Salon, SalonProfile, Service} from "../../api-models";
import {environment} from "../../../environments/environment";
import {catchError} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    public salon: Salon;
    public salonServices: Service[];
    public groupedServices: GroupServices;
    public nonWorkingDays: string[]

    public salonProfile: SalonProfile;
    private salonProfileSubject = new BehaviorSubject<SalonProfile>(null);
    readonly salonProfile$ = this.salonProfileSubject.asObservable();
    private serviceLoading: boolean = false;


    constructor(
        private httpClient: HttpClient,
        // private _route: ActivatedRoute,
        private _router: Router
    ) {
        /*console.log('_route', this._router.url)
        const slug = this._router.url.split('/')[1];
        console.log('slug', slug)*/
        /*    this.fetchSalonProfile(slug).then(isSuccess => {
                if (!isSuccess) {
                    _router.navigate(['404'], {queryParams: {old: slug}})
                }
            });*/


    }

    isFetched = false;
    isLoading = false;

    /*    async fetchSalonProfile(slug: string) {
            this.isLoading = true
            const response = await lastValueFrom(this.httpClient.get<SalonProfile>(environment.api_base_url + '/' + slug))
                .catch(e => {
                    return false;
                });
            if (typeof response !== "boolean") {
                this.isFetched = true;
                this.isLoading = false;
                this.salonProfile = response;
                this.salonProfileSubject.next(response);
                this.salon = response.salon;
                this.salonServices = response.services;
                this.groupedServices = this.groupTheServices(response.catServices);
                console.log('salonServices', this.salonServices)
                return true;
            }
        }*/

    fetchSalonProfile(slug: string) {
        this.isLoading = true
        return this.httpClient.get<SalonProfile>(environment.api_base_url + '/' + slug).pipe(
            tap((response) => {
                if (typeof response !== "boolean") {
                    this.isFetched = true;
                    this.isLoading = false;
                    this.salonProfile = response;
                    this.salonProfileSubject.next({...response, salon: {...response.salon, canBook: response.salon.canBook ?? true}});
                    this.salon = response.salon;
                    this.salonServices = response.services;
                    this.nonWorkingDays = response.workingDays;
                    this.groupedServices = this.groupTheServices(response.catServices, response.offers);
                    return true;
                }

            }),
            catchError((err) => {
                if (!err.error.isSuccess) {
                    this._router.navigate(['404'], {queryParams: {old: slug}})
                }
                return err
            }),
        );
    }

    /*   asd: any = {
           specified_id: [
               {
               catObj: {},
               catServices :[{}, {}, {}]
               },
               {
                   cat: {},
                   catServices : []
               },

           ]
           ,
           2: {}
       }*/

    groupTheServices1(catServices: { [key: string]: CatService[] }) {
        const group: { [specifiedId: string]: { [catId: string]: CatService[] } } = {1: {}, 2: {}};
        Object.entries(catServices).forEach((catEntries) => {
            const catId = '' + catEntries[0]
            const catServices = catEntries[1];
            catServices.forEach((service) => {
                if (!group[service.specified_id][catId])
                    group[service.specified_id][catId] = [];
                group[service.specified_id][catId].push(service);
            })


        })
        return group;
    }

    groupTheServices(catServices: { [p: string]: CatService[] }, offers: Offer[]): GroupServices {
        const group = {salonServices: [], homeServices: []};
        Object.entries(catServices).forEach((catEntries) => {
            const catId = '' + catEntries[0]
            const catServices = catEntries[1];
            const categoryDetails = this.salonProfile.categories.find(cat => cat.id === Number(catId));

            const subServicesInSalon = catServices.filter((services) => services.specified_id.toString() === '1')
            const subServicesInHomeService = catServices.filter((services) => services.specified_id.toString() === '2')

            if (!group.salonServices['_' + categoryDetails.id] && subServicesInSalon.length) {
                group.salonServices['_' + categoryDetails.id] = {
                    categoryDetails,
                    subServices: subServicesInSalon
                }
            }
            if (!group.homeServices['_' + categoryDetails.id] && subServicesInHomeService.length) {
                group.homeServices['_' + categoryDetails.id] = {
                    categoryDetails,
                    subServices: subServicesInHomeService
                }
            }
        });



        return {
            salonServices: [...Object.values(group.salonServices)],
            homeServices: [...Object.values(group.homeServices)]
        }
    }


}

export interface GroupServices {
    salonServices: {
        categoryDetails: Category;
        subServices: CatService[]
    }[];
    homeServices: {
        categoryDetails: Category;
        subServices: CatService[]
    }[];
}
